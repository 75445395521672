import React from 'react';
import { Icon } from "semantic-ui-react";
import style from './style.module.css';

const Partnerships = () => {
    return (
        <>
            <div className={style.container} id="parcerias">
                <div className={style.title}>
                    <h2>Parcerias</h2>
                </div>

                <div className={style.partners}>
                    <h4 className={style.partner}>BrazilLab</h4>
                    <div className={style.dash} />
                    <h4 className={style.partner}>MPRJ</h4>
                    <div className={style.dash} />
                    <h4 className={style.partner}>MPPE</h4>
                    <div className={style.dash} />
                    <h4 className={style.partner}>Grupo IMTS</h4>
                    <div className={style.dash} />
                    <h4 className={style.partner}>SkyeTech</h4>
                </div>
            </div >

            <div className={style.container}>
                <div className={style.title}>
                    <h2>Visite nossos perfis nas mídias sociais</h2>
                </div>

                <ul className={style.list}>
                    <li className={style.list_item}>
                        <Icon name="facebook" size="large" />
                        <a target="_blank" href='https://www.facebook.com/fabricadenegocioanalytics/' rel="noopener noreferrer">
                            @fabricadenegocioanalytics
                        </a>
                    </li>
                    <li className={style.list_item}>
                        <Icon name="linkedin" size="large" />
                        <a target="_blank" href='https://www.linkedin.com/company/fabricadenegocio/' rel="noopener noreferrer">
                            @fabricadenegocio
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Partnerships
