import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as articleActions from "../../../../store/actions/articles";
import style from "./style.module.css";
import { Button, Icon, Modal, Checkbox } from "semantic-ui-react";

class EditModal extends Component {
  state = {
    spotlight: null
  };

  componentDidMount() {
    if (!this.state.spotlight) {
      this.setState({ spotlight: this.props.article.spotlight });
    }
  }

  updateArticle = () => {
    let new_article = {
      ...this.props.article,
      spotlight: this.state.spotlight
    };
    this.props.actions.updateArticle(new_article);
    this.props.onClose();
  };

  render() {
    let { article } = this.props;

    return (
      <div
        style={{
          display: "inline",
          background: "transparent"
        }}
        className={style.container}
      >
        <Modal
          trigger={this.props.trigger}
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>Edição Pripriedades do Artigo</Modal.Header>
          <Modal.Content scrolling>
            <div className={style.container}>
              <div className={style.image_container}>
                <img
                  className={style.image}
                  src={article.images[0]}
                  alt="preview"
                />
              </div>

              <div>
                <span>{this.state.spotlight ? "Destaque" : "Comum"}</span>{" "}
                <Checkbox
                  toggle
                  checked={this.state.spotlight ? true : false}
                  onChange={(e, data) => {
                    this.setState({ spotlight: data.checked });
                  }}
                />
              </div>

              <div className={style.text}>{article.content}</div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.updateArticle}>
              Salvar <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    actions: bindActionCreators(articleActions, dispatch)
  };
};

export default connect(
  null,
  mapDispatch
)(EditModal);
