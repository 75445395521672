import moment from "moment";
import "moment/locale/pt-br"; // without this line it didn't work
moment.locale("pt-br");

export const addMonthDistinctionToArticles = articles => {
  let previous = "";

  articles.forEach((article, index) => {
    let current = moment(article.create_time).format("MMMM");

    if (previous !== current) {
      articles.splice(index, 0, { text: current });
      previous = current;
    }
  });

  return articles;
};
