import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as articlesActions from "../../../store/actions/articles";
import style from "./style.module.css";
import { Button } from "semantic-ui-react";
import EditModal from "./EditModal";
import { Spring } from "react-spring/renderprops";
import RemoveModal from "./RemoveModal";

class ArticleCard extends Component {
  state = {
    openEdit: false,
    openRemove: false
  };

  onOpenEditHandler = () => {
    this.setState({ openEdit: true });
  };

  onOpenRemoveHandler = () => {
    this.setState({ openRemove: true });
  };

  onCloseEditHandler = () => {
    this.setState({ openEdit: false });
  };

  onCloseRemoveHandler = () => {
    this.setState({ openRemove: false });
  };

  render() {
    return (
      <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
        {props => (
          <div style={props} className={style.container}>
            <div className={style.title_container}>
              <p>{this.props.article.title}</p>
            </div>

            <div className={style.preview_container}>
              {this.props.article.images.length > 0 ? (
                <img
                  className={style.media_preview}
                  src={this.props.article.images[0]}
                  alt="preview_article"
                />
              ) : (
                <img
                  className={style.media_preview}
                  src={"https://semantic-ui.com/images/wireframe/image.png"}
                  alt="media_preview"
                />
              )}
            </div>

            <div className={style.indicators_container}>
              <p>{this.props.article.spotlight ? "Destaque" : "Comum"}</p>
            </div>

            <div className={style.actions}>
              <EditModal
                trigger={
                  <Button icon="edit" onClick={this.onOpenEditHandler} />
                }
                article={this.props.article}
                onClose={this.onCloseEditHandler}
                open={this.state.openEdit}
              />

              <RemoveModal
                trigger={
                  <Button onClick={this.onOpenRemoveHandler} icon="trash" />
                }
                article={this.props.article}
                onClose={this.onCloseRemoveHandler}
                open={this.state.openRemove}
              />
            </div>
          </div>
        )}
      </Spring>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    actions: bindActionCreators(articlesActions, dispatch)
  };
};

export default connect(
  null,
  mapDispatch
)(ArticleCard);
