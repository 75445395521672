import React, { useState } from "react";
import style from "./style.module.css";
import {
  faAngleDoubleUp,
  faAngleDoubleDown
} from "@fortawesome/free-solid-svg-icons";
import { Keyframes, Transition, animated } from "react-spring/renderprops";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import objective from "../../../assets/images/objective.svg";
import analytics from "../../../assets/images/analytics.png";
import inovation from "../../../assets/images/inovation.svg";

const options = ["Objetivo", "Analytics", "Inovação"];
const images = [objective, analytics, inovation];
const texts = [
  styles => (
    <div style={styles}>
      <p>
        Transformar dados em informação, e informação em poder de decisão, pois
        acreditamos que só se gerencia o que se consegue medir. Este deve ser o
        foco do gestor moderno, e a Fábrica de Negócio acredita que pode apoiar
        o gestor nesta missão.
      </p>

      <p>
        A Fábrica de Negócio pode ajudar você a entender melhor a sua empresa,
        através da integração e extração de informações das mais diversas fontes
        de dados (arquivos TXT, planilhas e qualquer bancos de dados), gerando
        relatórios analíticos e painéis de gestão (dashboards), aumentando a sua
        eficiência operacional, maximizando seu resultado.
      </p>
    </div>
  ),
  styles => (
    <p style={styles}>
      Com quase uma década de experiência em Business Intelligence e Data
      Mining, a Fábrica adquiriu conhecimento nas principais plataformas de
      negócios de análises de dados do mercado, visando ofertar soluções que
      consigam extrair o máximo dos dados para apoiar a alta gestão de clientes
      e parceiros.
    </p>
  ),
  styles => (
    <p style={styles}>
      Através da expertise de seus profissionais, a Fábrica de Negócio investiu
      no desenvolvimento de soluções baseadas em Inteligência Artificial,
      algoritmos de ponta e Redes Neurais, que conseguem prever, classificar e
      indicar cenários, apoiando a tomada de decisão nos diversos níveis
      empresariais.
    </p>
  )
];

const Script = Keyframes.Spring(async next => {
  while (true) {
    await next({
      from: { radians: 0 },
      to: { radians: 2 * Math.PI }
    });
  }
});

export default function Objective() {
  const [selectedIndex, setIndex] = useState(0);

  return (
    <div className={style.objective}>
      <div className={style.circle}>
        <Transition
          config={{ duration: 1000 }}
          items={selectedIndex}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0, display: "none" }}
        >
          {index => props => (
            <img style={props} src={images[index]} alt="goal" />
          )}
        </Transition>

        <Transition
          config={{ duration: 1000 }}
          items={selectedIndex}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0, display: "none" }}
        >
          {index => props => <h2 style={props}>{options[index]}</h2>}
        </Transition>
      </div>

      <div className={style.objective_text}>
        <div className={style.arrows}>
          <Script native reset config={{ duration: 2000 }}>
            {({ radians }) => (
              <animated.div
                style={{
                  willChange: "transform",
                  transform: radians.interpolate(
                    r =>
                      `translate3d(0, ${5 *
                        Math.sin(r + (1 * 2 * Math.PI) / 5)}px, 0)`
                  )
                }}
              >
                <FontAwesomeIcon
                  onClick={() => {
                    if (selectedIndex !== 0) {
                      setIndex(selectedIndex - 1);
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: selectedIndex === 0 ? "#cacaca" : "#215d9f"
                  }}
                  icon={faAngleDoubleUp}
                />
              </animated.div>
            )}
          </Script>
        </div>

        <div className={style.text_container}>
          <Transition
            config={{ duration: 1000 }}
            items={selectedIndex}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0, display: "none" }}
          >
            {index => props => <>{texts[index](props)}</>}
          </Transition>
        </div>

        <div className={style.arrows}>
          <Script native reset config={{ duration: 2000 }}>
            {({ radians }) => (
              <animated.div
                style={{
                  willChange: "transform",
                  transform: radians.interpolate(
                    r =>
                      `translate3d(0, ${5 *
                        Math.cos(r + (2 * 2 * Math.PI) / 5)}px, 0)`
                  )
                }}
              >
                <FontAwesomeIcon
                  onClick={() => {
                    if (selectedIndex < 2) {
                      setIndex(selectedIndex + 1);
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: selectedIndex === 2 ? "#cacaca" : "#215d9f"
                  }}
                  icon={faAngleDoubleDown}
                />
              </animated.div>
            )}
          </Script>
        </div>
      </div>
    </div>
  );
}
