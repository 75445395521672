import React, { Component } from "react";
import devices from "../../../assets/images/devices.png";
import brazilLab from "../../../assets/images/brazillab2.png";
import style from "./style.module.css";

export default class MainPage extends Component {
  render() {
    return (
      <>
        <div className={style.banner}>
          <div className={style.banner_item}>
            <div className={style.image} />
          </div>
        </div>

        <div className={style.container}>
          <div className={style.title}>
            <h1>Gerando Informações para apoio à decisão.</h1>
          </div>

          <div className={style.devices_container}>
            <div>
              <img className={style.devices} src={devices} alt="device" />
            </div>
            
            <div className={style.brazillab_container}>
              <img
                className={style.brazillab}
                src={brazilLab}
                alt="brazillab"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
