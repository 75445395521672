import React/* , { Component } */ from "react";
// import { useSpring, animated } from "react-spring";
import style from "./style.module.css";
import business_analytics from "../../../assets/images/bar-chart@2x.png";
import innovation from "../../../assets/images/idea@2x.png";
import data_integration from "../../../assets/images/database@2x.png";
import special from "../../../assets/images/global@2x.png";

const BusinessUnit = () => {
  return (
    <div className={style.container}>
      <div className={style.title}>
        <h2>Nossas Unidades de Negócio</h2>
      </div>
      <div className={style.unit_container}>
        <div className={style.unit}>
          <div>
            <img src={business_analytics} alt="business analytics" />
          </div>
          <div className={style.description}>
            <h3>Unidade de Business Analytics</h3>
            <p>
              Esta unidade desenvolve soluções analíticas com base na plataforma
              Pentaho.
            </p>
          </div>
        </div>

        <div className={style.unit}>
          <div>
            <img src={innovation} alt="innovation" />
          </div>
          <div className={style.description}>
            <h3>Unidade de Inovação</h3>
            <p>
              Projetos de Data Mining, Inteligência Artificial para detecção de
              indícios de fraudes, análise de crédito e projetos inovadores.
            </p>
          </div>
        </div>

        <div className={style.unit}>
          <div>
            <img src={data_integration} alt="data integration" />
          </div>
          <div className={style.description}>
            <h3>Unidade de Integração de Dados</h3>
            <p>
              Desenvolvimento de integrações entre sistemas legados e os
              principais sistemas de gestão do mercado (ERPs), como por exemplo
              SAP, Consist, TOTVS, Pirâmide e Senior. Os nomes de
              empresas/produtos citados acima são apenas ilustrativos, pois o
              nosso negócio é INTEGRAR qualquer fonte de dados de qualquer
              sistema.
            </p>
          </div>
        </div>

        <div className={style.unit}>
          <div>
            <img src={special} alt="special" />
          </div>
          <div className={style.description}>
            <h3>Unidade de Projetos Especiais</h3>
            <p>
              Desenvolvimento de soluções verticais de análises de dados para
              mercados específicos, como Setor Público (Municipal, Estadual e
              Federal), Utilities (água, energia e gás), Planos de Saúde,
              Educacional, Varejo e Construção Civil, dentre outros.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessUnit;
