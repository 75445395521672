import React, { Component } from "react";
import { Input, Icon, Button } from "semantic-ui-react";
import style from "./style.module.css";
import { Spring } from "react-spring/renderprops";

export default class ImageForm extends Component {
  state = {
    medias: [],
    url: ""
  };

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  onClickHandler = () => {
    let newMedia = this.state.url;
    let newMedias = [...this.state.medias, newMedia];
    this.setState({ medias: newMedias, url: "" });
  };

  onChangeHandler = e => {
    this.setState({ url: e.target.value });
  };

  removeItem = item_index => {
    let newMediaList = this.state.medias.filter(
      (media, index) => index !== item_index
    );
    this.setState({ medias: newMediaList });
  };

  getMedias = () => {
    return this.state.medias;
  };

  render() {
    return (
      <div className={style.container}>
        <div className={style.image_loader}>
          <Input
            fluid
            action={{ icon: "plus", onClick: () => this.onClickHandler() }}
            placeholder={this.props.placeholder}
            value={this.state.url}
            onChange={this.onChangeHandler}
          />
        </div>

        <div className={style.images}>
          {this.state.medias.map((media_url, index) => (
            <Spring
              key={`${this.props.keyPrefix}${index}`}
              from={{ opacity: 0 }}
              to={{ opacity: 1 }}
            >
              {props => (
                <div style={props} className={style.image_container}>
                  <div>
                    <Icon name={this.props.item_icon} />
                  </div>

                  <div>
                    <p>
                      {media_url.slice(0, 30)}
                      <span>...</span>
                    </p>
                  </div>

                  <div>
                    <Button
                      size="mini"
                      circular
                      icon="remove"
                      onClick={() => this.removeItem(index)}
                    />
                  </div>
                </div>
              )}
            </Spring>
          ))}
        </div>
      </div>
    );
  }
}
