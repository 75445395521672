import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import style from "./style.module.css";
import fabrica from "../../../assets/images/logo-white-2.png";
import { Icon } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import * as sessionActions from "../../../store/actions";

function MenuItems(props) {
  return (
    <div className={style.container}>
      <div className={style.logo_container}>
        <img className={style.logo} src={fabrica} alt="Fábrica de Negócio" />
      </div>

      <div
        className={style.actions}
        onClick={() => props.actions.logout(props.history)}
      >
        <Icon name="log out" size="large" />
      </div>

      <ul className={style.items}>
        <li>
          <Link to="/admin">Criar Artigo</Link>
        </li>

        <li>
          <Link to="/gerencia-artigos">Gerenciar Artigos</Link>
        </li>
      </ul>
    </div>
  );
}

const mapDispatch = dispatch => {
  return {
    actions: bindActionCreators(sessionActions, dispatch)
  };
};

export default withRouter(
  connect(
    null,
    mapDispatch
  )(MenuItems)
);
