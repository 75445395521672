import React, { Component } from "react";
import Banner from "../Sections/Banner";
import Objective from "../Sections/Objective";
import Company from "../Sections/Company";
import BusinessUnit from "../Sections/BusinessUnit";
import Products from "../Sections/Products";
import Services from "../Sections/Services";
import Clients from "../Sections/Clients";
import Highlights from "../Sections/Highlights";
import Awards from "../Sections/Awards";
import Partnerships from "../Sections/Partnerships";
import Contato from "../Sections/Contato";
import Footer from "../../components/Footer";
import style from "./style.module.css";

export default class MainPage extends Component {
  render() {
    return (
      <div className={style.container}>
        <div className={style.section}>
          <div id="inicio" />
          <Banner />
        </div>

        {/* <div className={style.section}>
          <div id="objetivo" />
          <Objective />
        </div> */}

        <div className={style.section}>
          <Company />
        </div>
        {/* <div id="quem_somos" /> */}
        {/* <div className={style.section}>
          <BusinessUnit />
        </div> */}

        <div className={style.section}>
          <Products />
        </div>

        <div className={`${style.section}`}>
          <Services />
        </div>

        <div className={style.section}>
          <div />
          <Clients />
        </div>

        <div className={style.section}>
          <Highlights />
        </div>

        <div className={style.section}>
          <Awards />
        </div>

        {/* <div className={style.section}>
          <Partnerships />
        </div> */}

        <div className={style.section}>
          <Contato />
        </div>

        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
