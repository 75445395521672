import React from 'react';
// import xerlock from '../../../../assets/images/DescriptionXerlock.png';
import style from './style.module.css';
import highlight1 from '../../../assets/images/highlight1.jpeg';
import highlight2 from '../../../assets/images/highlight2.png';
import highlight3 from '../../../assets/images/highlight3.png';
import highlight4 from '../../../assets/images/highlight4.jpeg';
import highlight5 from '../../../assets/images/highlight5.jpeg';
import highlight6 from '../../../assets/images/highlight6.png';
import highlight7 from '../../../assets/images/highlight7.png';
import highlight8 from '../../../assets/images/highlight8.png';
import highlight9 from '../../../assets/images/highlight9.jpeg';
import highlight10 from '../../../assets/images/highlight10.jpeg';
import highlight11 from '../../../assets/images/highlight11.png';
import highlight12 from '../../../assets/images/highlight12.png';
import highlight13 from '../../../assets/images/highlight13.png';

const Highlights = () => {
    return (
        <div className={style.container} id="destaques_midia">
            <div className={style.title}>
                <h2>Destaques na mídia</h2>
            </div>
            <div className={style.description}>
                <p>
                    Nos últimos anos, conseguimos uma expressiva presença nas mídias impressas e digitais. 
                    Em 2021 ocupamos a 6ª posição, segundo o ranking de GovTechs da 100 Open Innovation StartUps. 
                    Em 2020 figuramos no relatório do Banco Interamericano de Desenvolvimento – BID, como uma 
                    das StartUps GovTechs com soluções de impacto para o Setor Público. Fizemos parte da matéria 
                    de capa da Exame (Jun 2019), sobre Governo 4.0. Fomos uma das StartUps convidadas para apresentar 
                    nossas soluções no evento GovTech 2019, promovido pela StartSe, em São Paulo. Também fizemos 
                    parte de reportagem no Valor Econômico, em matérias específicas sobre GovTechs.
                </p>
            </div>

            <div className={style.images_group}>
                <div className={style.image_container} style={{ width: '250px' }}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight1} alt="highlight 1 panel" />
                    {/* </div> */}
                </div>
                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight2} alt="highlight 2 panel" />
                    {/* </div> */}
                </div>

                <div className={`${style.image_container} ${style.highlight10}`} >
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight10} alt="highlight 10 panel" />
                    {/* </div> */}
                </div>
                <div className={`${style.image_container} ${style.highlight8}`}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight8} alt="highlight 8 panel" />
                    {/* </div> */}
                </div>

                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight3} alt="highlight 3 panel" />
                    {/* </div> */}
                </div>
                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight7} alt="highlight 7 panel" />
                    {/* </div> */}
                </div>
                
                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight9} alt="highlight 9 panel" />
                    {/* </div> */}
                </div>
                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight5} alt="highlight 5 panel" />
                    {/* </div> */}
                </div>

                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight4} alt="highlight 4 panel" />
                    {/* </div> */}
                </div>

                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight6} alt="highlight 6 panel" />
                    {/* </div> */}
                </div>
                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight11} alt="highlight 11 panel" />
                    {/* </div> */}
                </div>

                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight12} alt="highlight 12 panel" />
                    {/* </div> */}
                </div>
                <div className={style.image_container}>
                    {/* <div className={style.image_item}> */}
                        <img className={style.diagram} src={highlight13} alt="highlight 13 panel" />
                    {/* </div> */}
                </div>

            </div>
        </div >
    )
}

export default Highlights
