import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import style from "./style.module.css";
import MenuItems from "./MenuItems";

class DashboardLayout extends Component {
  componentDidUpdate() {
    if (!this.props.authenticated) {
      // console.log("not authenticated");
      this.props.history.push("/login");
    }
  }

  render() {
    return (
      <div className={style.container}>
        <aside className={style.sidenav}>
          <MenuItems />
        </aside>

        <main className={style.main}>{this.props.children}</main>
      </div>
    );
  }
}

const mapState = state => ({
  user: state.session.user,
  authenticated: state.session.authenticated
});

export default withRouter(
  connect(
    mapState,
    null
  )(DashboardLayout)
);
