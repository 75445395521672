import React from "react";
import style from "./style.module.css";
import logo from "../../assets/images/logo-white-2.png";

export default function Footer() {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.endereco}>
          <p>Endereço</p>
          <br />
          <p>Rua Manuel Bezerra, nr 64, Madalena, Recife, Pernambuco</p>
          <p>CEP 50.610-250</p>
        </div>

        <div className={style.logo}>
          <img
            className={style.logo_image}
            src={logo}
            alt="Fábrica de Negócio"
          />
        </div>
      </div>

      <div className={style.divider} />

      <div className={style.slogan_container}>
        <div className={style.slogan}>
          <p>"SÓ SE GERENCIA O QUE SE CONSEGUE MEDIR" - EDWARDS DEMING</p>
        </div>

        <div className={style.copyright}>
          <p>© FÁBRICA DE NEGÓCIO - TODOS OS DIREITOS RESERVADOS.</p>
        </div>
      </div>
    </div>
  );
}
