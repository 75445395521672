import React, { Component } from "react";
import style from "./style.module.css";
import TextEditor from "../../components/TextEditor";
import MediaForm from "../../components/MediaForm";
import { Button, Checkbox, Input } from "semantic-ui-react";
import axios from "../../axios";

export default class CreateArticle extends Component {
  state = {
    spotlight: false,
    title: ""
  };

  onClickCreateArticle = async () => {
    const videos = this.video.getMedias();
    const images = this.image.getMedias();
    const text = this.text.getText();
    const title = this.state.title;

    const article_data = {
      videos: videos,
      images: images,
      content: text,
      spotlight: this.state.spotlight,
      title: title
    };

    let response = await axios.post("/create_article", article_data);
    // console.log("response: ", response);
  };

  render() {
    return (
      <div className={style.container}>
        <div className={style.actions}>
          <div>
            <Button
              style={{ marginBottom: "20px" }}
              className={style.button}
              onClick={this.onClickCreateArticle}
            >
              Criar Artigo
            </Button>

            <div>
              <Input
                type="text"
                fluid
                placeholder="Título do Artigo"
                value={this.state.title}
                onChange={e => this.setState({ title: e.target.value })}
              />
            </div>
          </div>

          <div className={style.checkbox_container}>
            <div>
              <p>Destaque: </p>
            </div>

            <div>
              <Checkbox
                toggle
                onClick={() =>
                  this.setState({ spotlight: !this.state.spotlight })
                }
              />
            </div>
          </div>
        </div>

        <div className={style.input_url}>
          <MediaForm
            onRef={ref => (this.video = ref)}
            placeholder="URL Video"
            keyPrefix="Video"
            item_icon="video"
          />
        </div>

        <div className={style.input_image}>
          <MediaForm
            onRef={ref => (this.image = ref)}
            placeholder="URL Image"
            keyPrefix="Image"
            item_icon="file image"
          />
        </div>

        <div className={style.editor_container}>
          <TextEditor onRef={ref => (this.text = ref)} />
        </div>
      </div>
    );
  }
}
