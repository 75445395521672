import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MainLayout from "./hoc/MainLayout";
import DashboardLayout from "./hoc/DashboardLayout";
import MainPage from "./containers/MainPage";
// import News from "./containers/News";
import CreateArticle from "./containers/CreateArticle";
import Login from "./containers/Login";
import ManageArticles from "./containers/ManageArticles";
import WelcomeModal from "./components/WelcomeModal";

function App() {
  return (
    <div className="App">
      <WelcomeModal />
      <BrowserRouter basename="/portal">
        <Switch>
          <Route path="/" exact>
            <MainLayout>
              <MainPage />
            </MainLayout>
          </Route>

          {/* <Route path="/noticias" exact>
            <MainLayout>
              <News />
            </MainLayout>
          </Route> */}

          <Route path="/login" exact>
            <Login />
          </Route>

          <Route path="/admin" exact>
            <DashboardLayout>
              <CreateArticle />
            </DashboardLayout>
          </Route>

          <Route path="/gerencia-artigos" exact>
            <DashboardLayout>
              <ManageArticles />
            </DashboardLayout>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
