import * as actionTypes from "../actions/articles";

const initialState = {
  articles: null,
  normal_articles: null,
  spotlight_articles: null
};

const Articles = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_ARTICLES:
      return initialState;
    case actionTypes.GET_ARTICLES:
      return {
        ...state,
        articles: action.articles
      };
    case actionTypes.GET_NORMAL_ARTICLES:
      return {
        ...state,
        normal_articles: {
          ...action.articles,
          docs: action.articles.docs
        }
      };
    case actionTypes.GET_SPOTLIGHT_ARTICLES:
      return {
        ...state,
        spotlight_articles: {
          ...state.spotlight_articles,
          docs: [
            ...(state.spotlight_articles ? state.spotlight_articles.docs : []),
            ...action.articles.docs
          ]
        }
      };
    case actionTypes.REMOVE_ARTICLE:
      return {
        ...state,
        articles: {
          ...state.articles,
          docs: action.articles
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default Articles;
