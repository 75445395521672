import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as articleActions from "../../../../store/actions/articles";
import style from "./style.module.css";
import { Button, Icon, Modal } from "semantic-ui-react";

class EditModal extends Component {
  removeArticle = () => {
    // console.log("remove: ", this.props.article);
    this.props.actions.removeArticle(this.props.article._id);
    this.props.onClose();
  };

  render() {
    return (
      <div
        style={{
          display: "inline",
          background: "transparent"
        }}
        className={style.container}
      >
        <Modal
          trigger={this.props.trigger}
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>
            Tem certeza que deseja remover este artigo ?
          </Modal.Header>

          <Modal.Content image>
            <div>
              <div>
                {this.props.article.images.length > 0 ? (
                  <img src={this.props.article.images[0]} alt="preview" />
                ) : (
                  0
                )}
              </div>

              <Modal.Description>
                <p>
                  {this.props.article.content ? (
                    this.props.article.content
                  ) : (
                    <p>Sem Conteúdo Textual</p>
                  )}
                </p>
              </Modal.Description>
            </div>
          </Modal.Content>

          <Modal.Actions>
            <Button color="red" onClick={this.removeArticle}>
              Remover <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    actions: bindActionCreators(articleActions, dispatch)
  };
};

export default connect(
  null,
  mapDispatch
)(EditModal);
