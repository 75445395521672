import React, { useState } from "react";
import { Spring } from "react-spring/renderprops";
import style from "./style.module.css";
import {
  Form,
  Input,
  TextArea,
  Select,
  Button,
  Message
} from "semantic-ui-react";
import axios from "../../../axios";

const sendEmail = (data, showMessage) => {
  axios.post("/send_email", data).then(response => {
    showMessage();
  });
};

export default function Contato() {
  let [isSend, setSend] = useState(false);
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [subject, setSubject] = useState("Agendar uma visita");
  let [message, setMessage] = useState("");

  const options = [
    { key: "1", value: "Agendar uma visita", text: "Agendar uma visita" },
    {
      key: "2",
      value: "Mais Informações Sobre Produtos e Serviços",
      text: "Mais Informações Sobre Produtos e Serviços"
    },
    { key: "3", value: "Parcerias", text: "Parcerias" },
    { key: "4", value: "Outros Assuntos", text: "Outros Assuntos" }
  ];

  return (
    <div className={style.container}>
      <div className={style.title}>
        <h2>Vamos Conversar ?</h2>
        <h3>Conte-nos sobre seu projeto.</h3>
        <h4>
          Preencha o formulário abaixo.
        </h4>
      </div>

      {isSend ? (
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {props => (
            <Message style={props}>
              <Message.Header>
                Obrigado pelo seu interesse ! seu e-mail foi encaminhado.
              </Message.Header>
              <p>
                Aguarde um de nossos responsáveis entrar em contato no e-mail
                informado.
              </p>
            </Message>
          )}
        </Spring>
      ) : null}

      <div className={style.form} id="contato">
        <Form className={style.form_container}>
          <Input
            className={style.form_items}
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Nome"
          />
          <Input
            className={style.form_items}
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="E-mail"
          />
          <Select
            className={style.form_items}
            placeholder="Selecione o tipo do assunto"
            options={options}
            onChange={(e, { value }) => setSubject(value)}
          />
          <TextArea
            className={`${style.form_items} ${style.text_area}`}
            placeholder="Digite sua mensagem"
            rows={10}
            onChange={e => setMessage(e.target.value)}
          />
          <br />

          <Button
            color="black"
            onClick={() =>
              sendEmail(
                {
                  name,
                  email,
                  subject,
                  message
                },
                () => {
                  setSend(true);
                }
              )
            }
          >
            Enviar Mensagem
          </Button>
        </Form>
      </div>
    </div>
  );
}
