import axios from "../../axios";
import * as transforms from "../../helpers/transforms";
import * as R from "ramda";

export const CLEAR_ARTICLES = "CLEAR_ARTICLES";
export const GET_ARTICLES = "GET_ARTICLES";
export const GET_NORMAL_ARTICLES = "GET_NORMAL_ARTICLES";
export const GET_SPOTLIGHT_ARTICLES = "GET_SPOTLIGHT_ARTICLES";
export const REMOVE_ARTICLE = "REMOVE_ARTICLE";

export const clearArticles = () => {
  return {
    type: CLEAR_ARTICLES
  };
};

export const getArticles = (page = 1) => {
  return dispatch => {
    axios.get(`/get_articles/${page}`).then(response => {
      dispatch({
        type: GET_ARTICLES,
        articles: response.data
      });
    });
  };
};

export const getNormalArticles = (page = 1) => {
  return (dispatch, getState) => {
    let currentState = getState();

    axios.get(`/get_articles/${page}?priority=false`).then(response => {
      let article_data = response.data;
      
      let newDocs = transforms.addMonthDistinctionToArticles([
        ...(currentState.article.normal_articles
          ? currentState.article.normal_articles.docs.filter(
              article => !article.text
            )
          : []),
        ...article_data.docs
      ]);
      article_data.docs = newDocs;

      dispatch({
        type: GET_NORMAL_ARTICLES,
        articles: article_data
      });
    });
  };
};

export const getSpotlightArticles = (page = 1) => {
  return dispatch => {
    axios.get(`/get_articles/${page}?priority=true`).then(response => {
      dispatch({
        type: GET_SPOTLIGHT_ARTICLES,
        articles: response.data
      });
    });
  };
};

export const updateArticle = article => {
  return (dispatch, getState) => {
    const state = getState();
    axios.put("/update_article", article).then(response => {
      let updated_article = response.data;
      let current_articles = state.article.articles;
      let index = R.findIndex(R.propEq("_id", article._id))(current_articles);

      current_articles[index] = updated_article;

      dispatch({
        type: GET_ARTICLES,
        articles: current_articles
      });
    });
  };
};

export const removeArticle = id => {
  return dispatch => {
    axios.delete("/remove_article", { data: { id } }).then(response => {});
  };
};
