import React, { Component } from "react";
import { /* Button, Header, */ Container, Modal } from "semantic-ui-react";
// import style from "./style.module.css";

const clients = {
  // jornal: {
  //   title: "Jornal do Comércio",
  //   description: `
  //     O Sistema Jornal do Commercio de Comunicação é um conglomerado de mídia brasileiro com sede em Pernambuco, 
  //     pertencente ao Grupo JCPM. O projeto consiste em serviços especializados para integração e extração de dados 
  //     de sistemas legados (SCTV-TV, SCTV-Rádio e Easy Class), e planilhas para montagem de painéis de indicadores, 
  //     painéis de gestão detalhados da área de Inteligência de Mídia e Mercado das empresas 
  //     do SJCC (TV, Rádios, Jornal Impresso e Portal de Notícias). O principal objetivo é fazer uma gestão ainda mais 
  //     eficaz dos anunciantes e da audiência, agregando inteligência às decisões.
  //   `
  // },
  // hair: {
  //   title: "Hair Fly",
  //   description: `
  //     O desafio na Hair Fly foi integrar dados de seus sistemas legados e levar informações do chão de fábrica para 
  //     os gestores. Unimos informações das áreas de estoque, compras, vendas, logística, financeiro e produção. 
  //     Os painéis de gestão ficaram como devem ser: auto explicativos e com informações de qualidade.
  //   `
  // },
  // unibra: {
  //   title: "Unibra - Centro Universitário Brasileiro",
  //   description: `
  //     Geração de dados consolidados dos sistemas antigos de folha e RH, além de dois outros sistemas acadêmicos, 
  //     com bases de dados heterogêneas, para uma nova solução integrada, da empresa TOTVS. Um desafio e tanto, 
  //     pois os dados das Faculdades sofrem auditorias constantes, e as informações foram migradas com precisão e 
  //     em tempo record.
  //   `
  // },
  // unimed: {
  //   title: "Unimed Caruaru",
  //   description: `
  //     Montagem de data marts e cubos dimensionais analíticos para levar para alta gestão informações de controladoria 
  //     tanto do plano de saúde, quanto do hospital. A validação dos dados na origem foi um ponto crucial para o 
  //     desenvolvimento do trabalho.
  //   `
  // },
  // gilson: {
  //   title: "Gilson Cidrim",
  //   description: `
  //     Treinamento para equipe de TI e consultoria para desenvolvimento de painéis de gestão com dados dos exames 
  //     laboratoriais e dos clientes de suas mais de 70 unidades.
  //   `
  // },
  // ferreira: {
  //   title: "Ferreira Costa",
  //   description: `
  //     O projeto teve como finalidade realizar orquestração dos cerca de 20 scripts, gerando arquivos necessários 
  //     para a integração do sistema legado com a base Oracle, utilizando o Pentaho Community Edition, para montagem 
  //     de indicadores importantes, como por exemplo vendas regulares, vendas, indicadores de ruptura, indicadores 
  //     promocionais, dentre outros.
  //   `
  // },
  prefeitura: {
    title: "Controladoria Geral do Município de Recife",
    description: (
      <div>
        <p>
          Implantação da Solução Payroll Audit, (Mineração de Dados utilizando 
          algoritmos de Inteligência Artificial), que visa a realização de uma 
          auditoria completa na folha de pagamento de órgãos públicos, através 
          de trilhas de auditoria com base em testes estatísticos e de conformidade.
        </p>
        <p>Benefícios:</p>
        <ul>
          <li>
            Recuperação de receita através da auditoria de folhas anteriores
          </li>
          <br />
          <li>Auditoria prévia da folha antes do envio para o banco</li>
          <br />
          <li>Definição de trilhas de auditoria com base em Regras</li>
          <br />
          <li>Descoberta de novas regras com base no perfil dos dados</li>
          <br />
          <li>Cadastro de verbas versátil</li>
          <br />
          <li>Agilidade e foco no trabalho dos auditores</li>
          <br />
        </ul>
      </div>
    )
  },
  prodap: {
    title: "Prodap",
    description: `
      A PRODAP é o órgão gestor da Ata de Registro e Preços para contratação de serviços na plataforma Pentaho Business 
      Analytics da Fábrica de Negócio (Glen Coe Informática). Atuamos em conjunto no apoio em projetos de Analytics dos 
      demais órgãos do Estado do Amapá, como por exemplo o projeto de trilhas de auditoria do Observatório de 
      Despesas Públicas (trilhas de servidores e compras/licitações) da Controladoria Geral do Estado do 
      Amapá – CGE AP. Outros projetos nesta mesma linha também estão na pauta em outros órgãos no Estado, 
      como TCE AP e SEFAZ AP.
    `
  },
  emprel: {
    title: "Emprel",
    description: `
      A EMPREL é o órgão gestor da Ata de Registro e Preços para contratação de serviços na plataforma Pentaho Business 
      Analytics da Fábrica de Negócio (Glen Coe Informática). Atuamos em conjunto no apoio em projetos de Analytics 
      dos demais órgãos da Prefeitura da Cidade do Recife, como por exemplo os projetos de Auditoria de 
      Folha de Pagamento e o de Controle da Evasão Fiscal na Secretaria de Finanças.
    `
  },
  controladoria: {
    title: "Controladoria Geral do Estado de Pernambuco - CGE",
    description: `
      Prestação de serviços especializados de análise e extração de dados e automação de procedimentos utilizados para 
      acompanhamento da regularidade estadual perante o CAUC, em específico, as exigências fiscais, financeiras e jurídicas 
      e os sistemas que as suportam como e-CAC, CR-FGTS e CADIN.
    `
  },
  copergas: {
    title: "Copergás",
    description: `
      Esse projeto teve como finalidade a elaboração e montagem dos indicadores, cubos dimensionais, datamarts e painéis 
      de gestão (dashboards), integrando os dados da área Financeira/Controladoria da Companhia Pernambucana de 
      Gás - Copergás, utilizando a plataforma de software de Business Analytics Pentaho, em sua versão Community Edition. 
      Os dados a serem analisados eram oriundos de bases internas e externas, o que enriqueceu bastante a tomada de decisões.
    `
  },
  poli: {
    title: "Escola Politécnica de Pernambuco",
    description: `
      Migração de dados dos sistemas de RH, Acadêmico e Financeiro para um novo sistema integrado da TOTVS (Educacional), 
      consolidados todas as informações financeiras e a vida acadêmica dos alunos.
    `
  },
  reciprev: {
    title: "Reciprev",
    description: `
      A solução para o projeto abrange auditoria de folha de pagamento de inativos, dashboards condados do plano de saúde, 
      auditoria e controladoria da previdência, pagamentos a terceiros e acompanhamento do plano de saúde.
    `
  },
  secretariaFinancasRecife: {
    title: "Secretaria de Finanças",
    description: (
      <div>
        <p>
          A solução tem como finalidade proporcionar análises como subsídios
          para a detecção e prevenção de evasão fiscal, acessando
          primordialmente o banco de dados das Notas Fiscais Eletrônicas dos
          contribuintes (incluindo a DSRE - Declaração de Serviços Recebidos),
          em sua fase I, e outras 15 bases internas e externas previstas na fase
          II, utilizando mineração de dados e algoritmos de inteligência na
          plataforma de software de Business Analytics Pentaho, em sua versão
          Community Edition e outras tecnologias de Banco de Dados Analíticos.
        </p>

        <p>
          O alvo da solução é atuar em cima de três áreas: Faturamento,
          Recolhimento, e estudo de anomalias. Para isso, se fez necessário
          integrar várias bases de dados, dentre elas:
        </p>

        <ul>
          <li>Cartão de Crédito de serviços. Projeto de malha fina;</li>
          <li>Crea - Fiscalização de construção civil;</li>
          <li>Detran – fiscalização das auto-escolas;</li>
          <li>
            CELPE, COMPESA, RAIS/CAGED para identificar porte da empresa e
            cruzar com o faturamento;
          </li>
          <li>DIRF- Imposto Retido na fonte x faturamento;</li>
          <li>
            Secretaria de Educação- Consulta de número de alunos para cruzar com
            o faturamento das escolas;
          </li>
          <li>
            Dados de hospitais – Número de leitos e número de leitos de UTI;
          </li>
          <li>Juntas comerciais de todo Brasil;</li>
          <li>TJPE</li>
        </ul>
      </div>
    )
  },
  atiPE: {
    title: "Agência de Tecnologia da Informação do Governo do Estado de Pernambuco – ATI PE",
    description: `
      Desenvolvemos em parceria com a ATI o primeiro Gov Data Lake do Estado 
      de Pernambuco (BigData.PE). Solução é uma plataforma de governança, 
      compartilhamento e cruzamento de grandes volumes de dados, com ênfase em 
      segurança e LGPD, que visa coletar e compartilhar, de maneira segura, os 
      dados das diversas áreas de uma Secretaria, ou até mesmo entre Secretarias 
      e/ou órgãos externos, passando pela ingestão das coleções, camada de testes 
      e segurança, até as consultas e cruzamentos dos dados.
    `
  },
  secretariaSaudeRecife: {
    title: "Secretaria de Saúde da Prefeitura do Recife",
    description: (
      <div>
        <p>
          Desenvolvimento de implantação de soluções de análise e cruzamento de 
          dados de alto impacto para a Secretaria de Saúde, como por exemplo:
        </p>

        <ul>
          <li>BI Atenção Básica (eSUS AB)</li>
          <li>Sala de Situação com 91 indicadores</li>
          <li>
            Portal de combate às Arboviroses com geolocalização e Inteligência 
            Artificial (solução premiada no SECOP Brasília 2019)
          </li>
          <li>Gestão do Programa Nacional de Imunização - PNI</li>
          <li>Monitoramento Vacinados</li>
          <li>Gestão de Leitos</li>
        </ul>
      </div>
    )
  },
};

export default class ClientModal extends Component {
  render() {
    let { open, onClose, client } = this.props;
    return (
      <Modal open={open} onClose={onClose}>
        {client ? (
          <>
            <Modal.Header>{clients[client].title}</Modal.Header>
            <Modal.Content>
              <Container>{clients[client].description}</Container>
            </Modal.Content>
          </>
        ) : null}
      </Modal>
    );
  }
}
