import React, { Component } from "react";
import style from "./style.module.css";
import initial_value from "./config/initial";
import { Editor } from "slate-react";
import { Value } from "slate";
import { Icon } from "semantic-ui-react";
import Plain from '../../slate-schema/PlainTextSerializer';

export default class TextEditor extends Component {
  state = {
    value: Value.fromJSON(initial_value)
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  getText = () => {
    return Plain.serialize(this.state.value);
  };

  ref = editor => {
    this.editor = editor;
  };

  onChange = ({ value }) => {
    this.setState({ value });
  };

  onClickMark = (event, type) => {
    event.preventDefault();
    this.editor.toggleMark(type);
  };

  /**
   * Check if the current selection has a mark with `type` in it.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasMark = type => {
    const { value } = this.state;
    return value.activeMarks.some(mark => mark.type === type);
  };

  /**
   * Check if the any of the currently selected blocks are of `type`.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasBlock = type => {
    const { value } = this.state;
    return value.blocks.some(node => node.type === type);
  };

  render() {
    return (
      <div className={style.container}>
        <ul className={style.toolbar}>
          <li>H1</li>
          <li>H2</li>
          <li>
            <Icon name="quote right" />
          </li>
          <li>
            <Icon name="bold" />
          </li>
          <li>
            <Icon name="italic" />
          </li>
          <li>
            <Icon name="list ul" />
          </li>
          <li>
            <Icon name="image outline" />
          </li>
          <li>
            <Icon name="video camera" />
          </li>
        </ul>
        <div className={style.editor}>
          <Editor
            ref={this.ref}
            value={this.state.value}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}
