import React from 'react';
import style from './style.module.css';

const ClientBox = (props) => {
    return (
        <div className={style.container}>
            <img className={style.cliente_image} src={props.src} alt={props.alt}/>
        </div>
    )
}

export default ClientBox
