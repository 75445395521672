import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import style from "./style.module.css";
import logo from "../../assets/images/logo-white-2.png";
import { Input, Button } from "semantic-ui-react";
import { Spring } from "react-spring/renderprops";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../../store/actions/index";

class Login extends Component {
  state = {
    name: "",
    password: ""
  };

  componentDidUpdate() {
    if (this.props.authenticated) {
      // console.log("hero: ", this.props.history);
      this.props.history.push("/admin");
    }
  }

  onClickHandler = () => {
    const { login } = this.props.actions;
    let user = this.state;

    login(user, this.props.history);
  };

  onChangeHandler = (e, field) => {
    this.setState({
      [field]: e.target.value
    });
  };

  render() {
    return (
      <div className={style.container}>
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {props => (
            <div style={props} className={style.form_container}>
              <div className={style.logo}>
                <img src={logo} alt="logo" />
              </div>

              <div>
                <Input
                  className={style.form}
                  type="email"
                  placeholder="Nome do Usuário"
                  value={this.state.name}
                  onChange={e => this.onChangeHandler(e, "name")}
                />
              </div>

              <div>
                <Input
                  className={style.form}
                  type="password"
                  placeholder="Senha"
                  value={this.state.password}
                  onChange={e => this.onChangeHandler(e, "password")}
                />
              </div>

              <div>
                <Button onClick={this.onClickHandler} fluid color="green">
                  Entrar
                </Button>
              </div>
            </div>
          )}
        </Spring>
      </div>
    );
  }
}

const mapState = state => ({
  user: state.session.user,
  authenticated: state.session.authenticated
});

const mapDispatch = dispatch => {
  return {
    actions: bindActionCreators(sessionActions, dispatch)
  };
};

export default withRouter(
  connect(
    mapState,
    mapDispatch
  )(Login)
);
