import React, { useState } from "react";
import { Spring } from "react-spring/renderprops";
import style from "./style.module.css";

import angusGDL from "../../../assets/images/LogoAngusGDL.png";
import xerlock from "../../../assets/images/LogoXerlock.webp";
// import intelektas from "../../../assets/images/LogoIntelektas.png";

import AngusGDL from "./AngusGDL";
import Payroll from "./Payroll";
import Xerlock from "./Xerlock";
// import Intelektas from "./Intelektas";

const ANGUS_GDL = "angus_gdl";
const PAYROLL = "payroll";
const XERLOCK = "xerlock";
// const INTELEKTAS = "intelektas";

const customStyle = {
  [ANGUS_GDL]: { 
    from: (condition) => ({ width: condition ? "150px" : "200px", }),
    to: (condition) => ({ width: condition ? "200px" : "150px", })
  },
  // [INTELEKTAS]: { 
  //   from: (condition) => ({ width: condition ? "250px" : "300px", }),
  //   to: (condition) => ({ width: condition ? "300px" : "250px", })
  // },
}

const grow = (condition, product) => ({
  from: {
    // Dependendo do número de produtos, talvez não precise do Height
    // height: condition ? "200px" : "250px",
    width: condition ? "200px" : "250px",
    borderBottom: condition ? "1px solid white" : "5px solid #cacaca",
    ...customStyle[product]?.from?.(condition),
  },
  to: {
    // Dependendo do número de produtos, talvez não precise do Height
    // height: condition ? "250px" : "200px",
    width: condition ? "250px" : "200px",
    borderBottom: condition ? "5px solid #cacaca" : "1px solid white",
    ...customStyle[product]?.to?.(condition),
  }
});

const Products = () => {
  const [selectedItem, setItem] = useState(ANGUS_GDL);

  const state = {
    angus_gdl: selectedItem === ANGUS_GDL,
    payroll: selectedItem === PAYROLL,
    xerlock: selectedItem === XERLOCK,
    // intelektas: selectedItem === INTELEKTAS,
  };
  // console.log(grow(state.angus_gdl, ANGUS_GDL).from)

  const productsInfo = {
    angus_gdl: <AngusGDL />,
    payroll: <Payroll />,
    xerlock: <Xerlock />,
    // intelektas: <Intelektas />,
  }

  return (
    <div id="produtos">
      <div className={style.title}>
        <h2>Nossos Produtos</h2>
      </div>

      <div className={style.description}>
        <p>
          Focada no Setor Público, a Fábrica de Negócio conta hoje em seu portfólio com
          soluções de Analytics voltadas para o tratamento de grandes volumes de dados (big data),
          Inteligência Investigativa (Min. Público e SDSs), combate à fraudes fiscais e automatização
          de auditorias, mapeando através do cruzamento de grandes volumes de dados, toda a cadeia
          envolvida com os indícios, analisando papéis, empresas e processos. Dentre as soluções,
          podemos destacar:
        </p>
      </div>

      <div className={style.logo_container}>

        <div className={style.logo} onMouseDown={() => setItem("angus_gdl")}>
          <Spring from={grow(state.angus_gdl, ANGUS_GDL).from} to={grow(state.angus_gdl, ANGUS_GDL).to}>
            {props => <img style={props} src={angusGDL} alt="angus gdl" />}
          </Spring>
          <div className={style.description}>
            <p>
              <span style={{ background: '#48C056', color: 'black', border: '2px solid #5A99D3' }}>
                Angus - Gov Data Lake
              </span>, plataforma de governança, compartilhamento e
              cruzamento de grandes volumes de dados, com ênfase em segurança e LGPD.
            </p>
          </div>
        </div>

        <div className={style.logo} onMouseDown={() => setItem("xerlock")}>
          <Spring from={grow(state.xerlock, XERLOCK).from} to={grow(state.xerlock, XERLOCK).to}>
            {props => <img style={props} src={xerlock} alt="xerlock" />}
          </Spring>
          <div className={style.description}>
            <p>
              <span style={{ background: '#000000', color: 'white', border: '2px solid #000000' }}>
                Xerlock - Inteligência Investigativa
              </span>, solução vencedora do Processo de
              Inovação Aberta do MPPE, cujo objetivo é automatizar o mapeamento de todos
              os envolvidos no processo de investigação realizado pelos promotores dos
              Ministérios Públicos no Brasil.
            </p>
          </div>
        </div>

        {/* <div className={style.logo} onMouseDown={() => setItem("intelektas")}>
          <Spring from={grow(state.intelektas, INTELEKTAS).from} to={grow(state.intelektas, INTELEKTAS).to}>
            {props => <img style={props} src={intelektas} alt="intelektas" />}
          </Spring>
          <div className={style.description}>
            <p>
              <span style={{ background: '#5A9BD5', color: 'black', border: '2px solid #5A9BD5' }}>
                Intelektas - Plataforma de Inteligência Fiscal
              </span>, Auditoria de Instituições Financeiras (DESIF) e Auditoria de Folha de
              Pagamento de órgãos públicos.
            </p>
          </div>
        </div> */}

      </div>

      <div className={style.product_info}>
        {productsInfo[selectedItem]}
      </div>
    </div>
  );
};

export default Products;
