import React from 'react';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TransitionLayout from '../TransitionLayout';
import payroll from '../../../../assets/images/Payroll.jpg';
import impact from '../../../../assets/images/payroll_impact.jpg';
import style from './style.module.css';

const Payroll = () => {
    return (
        <TransitionLayout>
            <div className={style.container}>

                <div className={style.image_container}>
                    <img className={style.diagram} src={payroll} alt="payroll diagram"/>
                </div>

                <div className={style.text}>
                    <p>
                        Solução de Mineração de Dados utilizando algoritmos de Inteligência Artificial, 
                        que visa a realização de uma auditoria completa na folha de pagamento de órgãos 
                        públicos, através do cruzamento de regras de auditoria previstas pela Controladoria 
                        Geral da União com as verbas de folha de pagamento, acessando, inclusive, bases de dados 
                        externas, como SISOBITO e CADUNICO para confirmação de falecidos e vínculos com outras 
                        esferas públicas.
                    </p><br />

                    <p>
                        O Payroll Audit é utilizado para realizar a auditoria em folha de pagamento anteriores, aumentando 
                        o poder de recuperação de receita. Além disso, a solução pode ser executada antes de enviar os dados 
                        de folha para processamento e pagamento pelo banco, evitando perdas.
                    </p><br />
                </div>
               
                <div className={style.text}>
                    <p>Principais benefícios: </p><br />

                    <ul className={style.list}>
                        <li><FontAwesomeIcon icon={faCheck}/> Combate e evita pagamentos errados ou em duplicidade, checando os diversos vínculos dos servidores</li><br />
                        <li><FontAwesomeIcon icon={faCheck}/> Recuperar receita através da auditoria de folhas anteriores</li><br />
                        <li><FontAwesomeIcon icon={faCheck}/> Realizar auditoria prévia da folha antes do envio para o banco, evitando novos pagamentos indevidos</li><br />
                        <li><FontAwesomeIcon icon={faCheck}/> Analisar detalhadamente o contracheque do(s) Servidor(es) verba a verba.</li><br />
                        <li><FontAwesomeIcon icon={faCheck}/> Definir trilhas de auditoria com base em Regras e análises de Verbas</li><br />
                    </ul>
                </div>

                <div className={style.text}>
                    <p>A solução é implantada em até 30 dias, e o impacto é enorme na recuperação de receita através de pagamentos errados ou indevidos.</p><br />
                </div>

                <div className={style.image_container}>
                    <img className={style.impact} src={impact} alt="impact table"/>
                </div>
            </div>
        </TransitionLayout>
    )
}

export default Payroll
