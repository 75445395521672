import React, { useState } from "react";
import style from "./style.module.css";
import ClientBox from "./ClientBox";
import ClientModal from "../../../components/ClientModal";

// // Setor Privado
// import jc from "../../../assets/images/jornal_comercio.png";
// import hairfly from "../../../assets/images/ichf-min.png";
// import unibra from "../../../assets/images/unibra.jpg";
// import unimed from "../../../assets/images/unimed.png";
// import gilson from "../../../assets/images/lgc-min.png";
// import ferreira_costa from "../../../assets/images/ferreira-costa.png";

// Setor Público
import controladoria_recife from "../../../assets/images/controladoria.png";
import secretariaFinancasRecife from "../../../assets/images/secretaria.png";
import prodap from "../../../assets/images/prodap-min.png";
import emprel from "../../../assets/images/emprel-min.png";
import copergas from "../../../assets/images/copergas-min.png";
import poli from "../../../assets/images/upep-min.png";
import controladoria from "../../../assets/images/controladoria.jpg";
import reciprev from "../../../assets/images/logo_reciprev.png";
import atiPE from "../../../assets/images/logo_ati.jpeg";
import secretariaSaudeRecife from "../../../assets/images/logo_secretaria_saude_recife.jpeg";

const Clients = () => {
  const [OpenModal, setOpenModal] = useState({ client: "", open: false });

  return (
    <div className={style.container} id="clientes">
      <div className={style.title}>
        <h2>Nossos Clientes</h2>
      </div>

      <div className={style.clients_container}>
        {/* <div className={style.private}>
          <h2 className={style.title}>Setor Privado</h2>

          <div className={style.clients}>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "jornal", open: true })}
            >
              <ClientBox src={jc} alt="jornal comercio" />
            </div>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "hair", open: true })}
            >
              <ClientBox src={hairfly} alt="Hair Fly" />
            </div>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "unibra", open: true })}
            >
              <ClientBox src={unibra} alt="Unibra" />
            </div>
          </div>

          <div className={style.clients}>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "unimed", open: true })}
            >
              <ClientBox src={unimed} alt="Unimed" />
            </div>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "gilson", open: true })}
            >
              <ClientBox src={gilson} alt="Gilson" />
            </div>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "ferreira", open: true })}
            >
              <ClientBox src={ferreira_costa} alt="Ferreira Costa" />
            </div>
          </div>
        </div>

        <div className={style.dummy} />
        <div className={style.divider} /> */}

        <div />
        <div className={style.public}>
          <h2 className={style.title}>Setor Público</h2>

          <div className={style.clients}>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "prefeitura", open: true })}
            >
              <ClientBox src={controladoria_recife} alt="Prefeitura" />
            </div>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "prodap", open: true })}
            >
              <ClientBox src={prodap} alt="Prodap" />
            </div>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "emprel", open: true })}
            >
              <ClientBox src={emprel} alt="Emprel" />
            </div>
          </div>

          <div className={style.clients}>
            <div
              className={style.client}
              onClick={() =>
                setOpenModal({ client: "controladoria", open: true })
              }
            >
              <ClientBox src={controladoria} alt="Controladoria" />
            </div>

            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "copergas", open: true })}
            >
              <ClientBox src={copergas} alt="Copergás" />
            </div>

            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "poli", open: true })}
            >
              <ClientBox src={poli} alt="UPE - Poli" />
            </div>
          </div>

          <div className={style.clients}>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "reciprev", open: true })}
            >
              <ClientBox src={reciprev} alt="Reciprev" />
            </div>

            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "secretariaFinancasRecife", open: true })}
            >
              <ClientBox src={secretariaFinancasRecife} alt="Secretaria de finanças de Recife" />
            </div>

            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "atiPE", open: true })}
            >
              <ClientBox src={atiPE} alt="ATI de Pernambuco" />
            </div>
          </div>

          <div className={style.clients}>
            <div
              className={style.client}
              onClick={() => setOpenModal({ client: "secretariaSaudeRecife", open: true })}
            >
              <ClientBox src={secretariaSaudeRecife} alt="Secretaria de Saúde de Recife" />
            </div>

          </div>
        </div>
        <div />

      </div>

      <ClientModal
        open={OpenModal.open}
        client={OpenModal.client}
        onClose={() => setOpenModal({ client: "", open: false })}
      />
    </div>
  );
};

export default Clients;
