import React, { Component } from "react";
import { Button, Container, Modal } from "semantic-ui-react";
import newlogo from "../../assets/images/FabricaAgoraEhXerlock.png";

const textStyle = {
  fontSize: '18px',
};

export default class WelcomeModal extends Component {
  state={
    open: true
  }
  render() {
    return (
      <Modal open={this.state.open} onClose={() => this.setState({open: false})}>
        <Modal.Header>A Fábrica de Negócio agora é <span style={{ color: "#6C79FE" }}>Xerlock Smart Gov</span>!!!</Modal.Header>
        <Modal.Content>
          <Container>
            <p style={{ fontSize: '18px', }}>
              A marca Fábrica de Negócio, que foi destaque no mercado de GOVTECHs no Brasil, figurando no ranking Top 10 GovTechs da 100 Open Startups, por três anos consecutivos, agora se posicionará nesse mercado como Xerlock, marca de um de nossos principais produtos de análise e cruzamento de grandes volumes de dados.
            </p>
            <p style={{ fontSize: '18px', }}>
              A marca Xerlock Smart Gov já nasce com experiência de mais de 125 mil horas de projetos no setor público, herança de oito anos da trajetória da Fábrica de Negócio.
              A Xerlock continuará o desenvolvimento e evolução tecnológica de seus produtos de dados, como por exemplo, o Angus GD, plataforma de governança de dados, e o Xerlock inteligência investigativa, além de continuar a atuar em projetos de Analytics e Inteligência Artificial, aproveitando a expertise de seus profissionais na área de análise de dados.
            </p>
            <h2 style={{ textAlign: 'center' }}>
              Vida longa à <span style={{ color: "#6C79FE" }}>Xerlock Smart Gov</span>!!!
            </h2>
          </Container>
          <img
            // className={style.logo_image}
            style={{marginLeft: "10%"}}
            src={newlogo}
            alt="Fábrica de Negócio agora é Xerlock Smart Gov"
          />
            <a style={{ fontSize: '17', float: 'right' }} target="_blank" href='https://www.xerlock.tech/' rel="noopener noreferrer">
              Ir para o novo website da Xerlock Smart Gov aqui.
            </a>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({open: false})}>
            Fechar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
