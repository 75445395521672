import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import style from "./style.module.css";
import { Spring } from "react-spring/renderprops";
import { useSpring, animated } from "react-spring";
import { HashLink } from "react-router-hash-link";
import logo from "../../assets/images/logo.png";
import logo_white from "../../assets/images/logo-white-2.png";
import Scrollspy from "react-scrollspy";
import { Icon } from "semantic-ui-react";

const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;

  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth"
  });
};

const TopNavBar = props => {
  const { pathname } = props.location;
  const isNewsPage = pathname === "/noticias/";
  const [open, setOpen] = useState(false);
  let config = {
    background: props.changeStyle ? "rgb(255, 255, 255)" : "rgba(0, 0, 0, 0.0)",
    color: props.changeStyle ? "rgba(0, 0, 0, 0)" : "rgb(255, 255, 255)"
  };

  if (isNewsPage) {
    config = {
      background: "rgb(255, 255, 255)",
      color: "rgb(255, 255, 255)"
    };
  }

  let propsSpring = useSpring(config);

  return (
    <div className={style.nav_container}>
      <animated.nav
        style={propsSpring}
        className={props.changeStyle ? style.nav_active : style.nav}
      >
        <h4 className={style.nav_logo}>
          <HashLink
            scroll={el => scrollWithOffset(el, 150)}
            to="/#inicio"
            smooth
          >
            {props.changeStyle || isNewsPage ? (
              <img
                className={style.nav_logo}
                src={logo}
                alt="Fábrica de Negócio"
              />
            ) : (
              <img
                className={style.nav_logo}
                src={logo_white}
                alt="Fábrica de Negócio"
              />
            )}
          </HashLink>

          <div
            className={
              props.changeStyle || isNewsPage ? style.icons_active : style.icons
            }
          >
            <a
              href="https://www.facebook.com/fabricadenegocioanalytics/"
              target="__blank"
            >
              <Icon name="facebook" size="large" />
            </a>
          </div>

          <div
            className={
              props.changeStyle || isNewsPage ? style.icons_active : style.icons
            }
          >
            <a
              href="https://br.linkedin.com/company/fabricadenegocio?trk=public_profile_topcard_current_company"
              target="__blank"
            >
              <Icon name="linkedin" size="large" />
            </a>
          </div>
        </h4>

        <Scrollspy
          items={[
            // "news",
            // "objetivo",
            "quem_somos",
            "produtos",
            "servicos",
            "clientes",
            "destaques_midia",
            "premiacoes",
            // "parcerias",
            "contato"
          ]}
          currentClassName={style.is_current}
          className={
            props.changeStyle ? style.nav_items_active : style.nav_items
          }
        >
          {/* <li>
            <HashLink
              scroll={el => scrollWithOffset(el, 150)}
              to="/noticias/#news"
              smooth
              className={props.changeStyle ? style.links_active : style.links}
            >
              notícias
            </HashLink>
          </li> */}
          {/* <li>
            <HashLink
              scroll={el => scrollWithOffset(el, 150)}
              smooth
              to="/#objetivo"
              className={props.changeStyle ? style.links_active : style.links}
            >
              nosso objetivo
            </HashLink>
          </li> */}
          <HashLink
            scroll={el => scrollWithOffset(el, 100)}
            smooth
            to="/#quem_somos"
            className={props.changeStyle ? style.links_active : style.links}
          >
            <li>
              quem somos
            </li>
          </HashLink>
          <HashLink
            scroll={el => scrollWithOffset(el, 100)}
            smooth
            to="/#produtos"
            className={props.changeStyle ? style.links_active : style.links}
          >
            <li>
              produtos
            </li>
          </HashLink>
          <HashLink
            scroll={el => scrollWithOffset(el, 0)}
            smooth
            to="/#servicos"
            className={props.changeStyle ? style.links_active : style.links}
          >
            <li>
              serviços
            </li>
          </HashLink>
          <HashLink
            scroll={el => scrollWithOffset(el, 100)}
            smooth
            to="/#clientes"
            className={props.changeStyle ? style.links_active : style.links}
          >
            <li>
              clientes
            </li>
          </HashLink>
          <HashLink
            scroll={el => scrollWithOffset(el, 100)}
            smooth
            to="/#destaques_midia"
            className={props.changeStyle ? style.links_active : style.links}
          >
            <li>
              destaques na mídia
            </li>
          </HashLink>
          <HashLink
            scroll={el => scrollWithOffset(el, 100)}
            smooth
            to="/#premiacoes"
            className={props.changeStyle ? style.links_active : style.links}
          >
            <li>
              premiações
            </li>
          </HashLink>
          {/* <li>
            <HashLink
              scroll={el => scrollWithOffset(el, 100)}
              smooth
              to="/#parcerias"
              className={props.changeStyle ? style.links_active : style.links}
            >
              parcerias
            </HashLink>
          </li> */}
          <HashLink
            scroll={el => scrollWithOffset(el, 250)}
            smooth
            to="/#contato"
            className={props.changeStyle ? style.links_active : style.links}
          >
            <li>
              contato
            </li>
          </HashLink>
        </Scrollspy>

        <Spring
          from={{ transform: open ? "translateX(100%)" : "translateX(0%)" }}
          to={{ transform: open ? "translateX(0%)" : "translateX(100%)" }}
        >
          {props => (
            <ul style={props} className={style.nav_items_mob}>
              {/* <li>
                <HashLink
                  scroll={el => scrollWithOffset(el, 150)}
                  to="/noticias/#news"
                  smooth
                  className={style.links_mobile}
                >
                  notícias
                </HashLink>
              </li> */}
              {/* <li>
                <HashLink
                  scroll={el => scrollWithOffset(el, 150)}
                  smooth
                  to="/#objetivo"
                  className={style.links_mobile}
                >
                  nosso objetivo
                </HashLink>
              </li> */}
              <HashLink
                scroll={el => scrollWithOffset(el, 250)}
                smooth
                to="/#quem_somos"
                className={style.links_mobile}
              >
                <li>
                  quem somos
                </li>
              </HashLink>
              <HashLink
                scroll={el => scrollWithOffset(el, 150)}
                smooth
                to="/#produtos"
                className={style.links_mobile}
              >
                <li>
                  produtos
                </li>
              </HashLink>
              <HashLink
                scroll={el => scrollWithOffset(el, 100)}
                smooth
                to="/#servicos"
                className={style.links_mobile}
              >
                <li>
                  serviços
                </li>
              </HashLink>
              <HashLink
                scroll={el => scrollWithOffset(el, 150)}
                smooth
                to="/#clientes"
                className={style.links_mobile}
              >
                <li>
                  clientes
                </li>
              </HashLink>
              <HashLink
                scroll={el => scrollWithOffset(el, 150)}
                smooth
                to="/#destaques_midia"
                className={style.links_mobile}
              >
                <li>
                  destaques na mídia
                </li>
              </HashLink>
              <HashLink
                scroll={el => scrollWithOffset(el, 150)}
                smooth
                to="/#premiacoes"
                className={style.links_mobile}
              >
                <li>
                  premiações
                </li>
              </HashLink>
              {/* <li>
                <HashLink
                  scroll={el => scrollWithOffset(el, 100)}
                  smooth
                  to="/#parcerias"
                  className={style.links_mobile}
                >
                  parcerias
                </HashLink>
              </li> */}
              <HashLink
                scroll={el => scrollWithOffset(el, 250)}
                smooth
                to="/#contato"
                className={style.links_mobile}
              >
                <li>
                  contato
                </li>
              </HashLink>
            </ul>
          )}
        </Spring>

        <div className={style.burguer} onClick={() => setOpen(!open)}>
          <div className={style.line1} />
          <div className={style.line2} />
          <div className={style.line3} />
        </div>
      </animated.nav>
    </div>
  );
};

export default withRouter(TopNavBar);
