import React from "react";
import { Spring } from "react-spring/renderprops";

const TransitionLayout = props => {
  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {propsAnimation => <div style={propsAnimation}>{props.children}</div>}
    </Spring>
  );
};

export default TransitionLayout;
