import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Pagination } from "semantic-ui-react";
import style from "./style.module.css";
import * as articleActions from "../../store/actions/articles";
import ArticleCard from "./ArticleCard";

class ManageArticles extends Component {
  state = {
    activePage: 1
  };

  componentDidMount() {
    this.props.actions.getArticles(1);
  }

  handlePaginationChange = (e, { activePage }) => {
    this.props.actions.getArticles(activePage);
    this.setState({ activePage });
  };

  render() {
    return (
      <div className={style.container}>
        {this.props.articles ? (
          <div className={style.articles_container}>
            {this.props.articles.docs.map(doc => (
              <ArticleCard key={doc._id} article={doc} />
            ))}
          </div>
        ) : (
          <p>Carregando ...</p>
        )}

        <div className={style.pagination_container}>
          {this.props.articles ? (
            <Pagination
              activePage={this.state.activePage}
              onPageChange={this.handlePaginationChange}
              totalPages={this.props.articles.totalPages}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapState = state => {
  return {
    articles: state.article.articles
  };
};

const mapDispatch = dispatch => {
  return {
    actions: bindActionCreators(articleActions, dispatch)
  };
};

export default connect(
  mapState,
  mapDispatch
)(ManageArticles);
