import React from 'react';
import TransitionLayout from '../TransitionLayout';
import xerlock from '../../../../assets/images/DescriptionXerlock.png';
import style from './style.module.css';

const Xerlock = () => {
    return (
        <TransitionLayout>
            <div className={style.container}>

                <div className={style.text}>
                    <p>
                        Xerlock - Inteligência Investigativa, solução vencedora do Processo de Inovação Aberta do MPPE,
                        e selecionada para o Aceleração no MPRJ, cujo objetivo é automatizar o mapeamento de todos os
                        envolvidos no processo de investigação realizado pelos promotores dos Ministérios Públicos no Brasil.
                    </p><br />

                    <p>
                        No Xerlock é possível coletar e cruzar informações sobre pessoas, fatos ou organizações vinculadas ao
                        processo investigativo, através de integrações de bases de dados internas do MP ou externas, para efeito de
                        enriquecimento do modelo de Inteligência Artificial que valida as hipóteses e cria vínculos entre os envolvidos.
                    </p><br />

                    <p>
                        A plataforma atua apoiando a área de tecnologia na coleta e integração de diversas fontes de dados, 
                        e apoia também o Promotor e o time envolvido na investigação, sugerindo hipóteses, tipificações e 
                        gerando o relatório final do processo, permitindo tratar e anexar análises, textos, áudio, vídeos e imagens.
                    </p><br />
                </div>

                <div className={style.image_container}>
                    <img className={style.diagram} src={xerlock} alt="xerlock panel" />
                </div>
            </div>
        </TransitionLayout>
    )
}

export default Xerlock
