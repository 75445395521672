import React from 'react';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TransitionLayout from '../TransitionLayout';
// import angusGDL from '../../../../assets/images/DescriptionAngusGDL.jpeg';
import angusGDL from '../../../../assets/images/LogoAngusGDL.png';
import style from './style.module.css';

const AngusGDL = () => {
    return (
        <TransitionLayout>
            <div className={style.container}>

                <div className={style.image_container}>
                    {/* <img className={style.diagram} src={angusGDL} alt="angus gdl diagram" /> */}
                    <img className={style.diagram} src={angusGDL} alt="angus gdl logo" style={{background: 'white', borderRadius: '15px'}} />
                </div>
                <div className={style.title}>
                    <p>
                    Plataforma de governança, compartilhamento e cruzamento de 
                    grandes volumes de dados, com ênfase em segurança e LGPD.
                    </p><br />
                </div>

                <div className={style.text}>
                    <p>
                    Angus - Gov Data Lake é uma plataforma de governança, compartilhamento e cruzamento de 
                    grandes volumes de dados, com ênfase em segurança e LGPD, que visa coletar e compartilhar, 
                    de maneira segura, os dados das diversas áreas de uma Secretaria, ou até mesmo entre Secretarias 
                    e/ou órgãos externos, passando pela ingestão das coleções, camada de testes e segurança, até 
                    as consultas e cruzamentos dos dados
                    </p><br />
                </div>

                <div className={style.text}>
                    <p>Principais funcionalidades: </p><br />

                    <ul className={style.list}>
                        <li><FontAwesomeIcon icon={faCheck} /> Foco na governança dos dados;</li><br />
                        <li><FontAwesomeIcon icon={faCheck} /> Coleta de fontes de dados diversas e armazenamento em bases de Big Data visando aumentar a velocidade de acesso às coleções, utilizando tecnologias Open Source;</li><br />
                        <li><FontAwesomeIcon icon={faCheck} /> Permite uma ingestão de dados de forma automatizada;</li><br />
                        <li><FontAwesomeIcon icon={faCheck} /> Controla o acesso às coleções de dados (inserção e consulta) de forma segura, através da parametrização de 5 perfis de usuários distintos;</li><br />
                        <li><FontAwesomeIcon icon={faCheck} /> Permite total auditoria de logs da plataforma;</li><br />
                        <li><FontAwesomeIcon icon={faCheck} /> Possui uma camada de visualização integrada ao Gov Data Lake para consumo dos dados de forma gráfica, simples e intuitiva, evitando gastos com contratos de outras plataformas de Business Analytics;</li><br />
                        <li><FontAwesomeIcon icon={faCheck} /> Possui metodologia de gestão dos dados com ênfase na LGPD;</li><br />
                        <li><FontAwesomeIcon icon={faCheck} /> Garante o compartilhamento de dados de forma segura.</li><br />
                    </ul>
                </div>
            </div>
        </TransitionLayout>
    )
}

export default AngusGDL;
