import React, { Component } from "react";
import style from "./style.module.css";

export default class Company extends Component {
  render() {
    return (
      <div className={style.company} id="quem_somos">
        <div className={style.title}>
          <h2>Quem Somos</h2>
        </div>
        <div className={style.description}>
          <p>
            Somos uma GovTech fundada em 2016, focada em desenvolver soluções com o uso de
            ferramentas de Analytics {'&'} Data Mining com Inteligência Artificial em projetos no Setor Público,
            como por exemplo auditoria em folha de pagamento, inteligência investigativa (Min. Público),
            gestão de dados da saúde em Estados e Municípios e combate à evasão fiscal, rastreando indícios
            de fraudes/irregularidades, apoiando na recuperação de receita, no controle de despesas e
            na melhoria da Gestão Pública.
          </p>
        </div>

        <div className={style.pillar}>
          <h3>Expertise:</h3>
          <p>
            O nosso time de Cientistas de Dados implementou diversos projetos, totalizando mais de 90 mil horas 
            em projetos de análise e cruzamento de dados.
          </p>
        </div>

        <div className={style.pillar}>
          <h3>Analytics:</h3>
          <p>
            Com quase uma década de experiência em Business Analytics e Data Mining, a Fábrica de Negócio adquiriu
            conhecimento nas principais plataformas de open source de análises de dados do mercado, visando ofertar
            soluções que consigam extrair o máximo dos dados para apoiar a alta gestão de clientes e parceiros.
          </p>
        </div>

        <div className={style.pillar}>
          <h3>Inovação:</h3>
          <p>
            Através da expertise de seus profissionais, a Fábrica de Negócio investiu no desenvolvimento de soluções
            baseadas em Inteligência Artificial, algoritmos de ponta e Redes Neurais, que conseguem prever, classificar
            e indicar cenários, apoiando a tomada de decisão nas mais diversas áreas do Setor Público.
          </p>
        </div>

        <div className={style.pillar}>
          <h3>Objetivo:</h3>
          <p>
            Nosso objetivo é apoiar o Setor Público através de integrações entre as diversas fontes, transformando dados
            em informação, e informação em poder de decisão, pois acreditamos que só se gerencia o que se consegue medir.
            Este deve ser o foco do gestor público moderno, e a Fábrica de Negócio acredita que pode apoiá-lo nesta nobre missão.
          </p>
        </div>

        <div className={style.pillar}>
          <h3>Missão:</h3>
          <p>
            Desenvolver e entregar serviços e soluções de análise e cruzamento de
            grandes volumes de dados, através do uso de tecnologias e ferramentas próprias,
            para proporcionar a descoberta de conhecimento, visando apoiar o Gestor Público
            na tomada de decisões, mantendo-se na vanguarda do estudo, e na aplicação de novas
            tecnologias para propor sempre soluções de alto impacto.
          </p>
        </div>

        <div className={style.pillar}>
          <h3>Visão:</h3>
          <p>
            Ser uma GovTech Global, referência na criação e distribuição de
            soluções e serviços que contribuam com as melhorias na gestão pública e
            inteligência investigativa, com impactos claros e mensuráveis.
          </p>
        </div>

        <div className={style.pillar}>
          <h3>Valores:</h3>
          <p>
            Dentre os valores praticados na empresa, destacamos: inovação, transparência,
            resiliência, compliance, respeito e seriedade. Temos como objetivo possibilitar, sempre,
            um bom ambiente de trabalho, confortável e produtivo para os nossos colaboradores, prezando
            pela transparência na relação com nossos clientes, fornecedores e sociedade.
          </p>
        </div>
      </div>
    );
  }
}
