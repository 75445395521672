import React from 'react';
// import xerlock from '../../../../assets/images/DescriptionXerlock.png';
import style from './style.module.css';
import award1 from '../../../assets/images/Award1.jpeg';
import award2 from '../../../assets/images/Award2.jpeg';
import award3 from '../../../assets/images/Award3.jpeg';
import award4 from '../../../assets/images/Award4.png';
import award5 from '../../../assets/images/Award5.jpeg';
import award6 from '../../../assets/images/Award6.jpeg';

const Awards = () => {
    return (
        <div className={style.container} id="premiacoes">
            <div className={style.title}>
                <h2>Premiações</h2>
            </div>

            <div className={style.images_group}>
                <div className={style.image_item}>
                    <img className={style.diagram} src={award1} alt="award 1 panel" />
                </div>
                <p>
                    Selecionada entre as 10 GovTechs (6ª posição) em 2021, segundo o ranking a 100 Open StartUps.
                </p>
            </div>
            <div className={style.images_group}>
                <p>
                    StartUp selecionada pelo Itamaraty e Estonia Hub para participação na Missão Virtual Brasil
                    Estônia, Out/21.
                </p>
                <div className={style.image_item}>
                    <img className={style.diagram} src={award2} alt="award 2 panel" />
                </div>
            </div>
            <div className={style.images_group}>
                <div className={style.image_item}>
                    <img className={style.diagram} src={award3} alt="award 3 panel" />
                </div>
                <p>
                    Vencedor do desafio de Gestão de Pessoal no setor público, no Programa de Aceleração do Hub de
                    GovTechs BrazilLab, em 2019, apresentando a solução de auditoria em folha de pagamentos (Payroll Audit).
                </p>
            </div>
            <div className={style.images_group}>
                <p>
                    Vencedor do Programa de Inovação Aberta do Ministério Público de Pernambuco no desafio de Soluções
                    para Inteligência Investigativa, com a plataforma Xerlock.
                </p>
                <div className={style.image_item}>
                    <img className={style.diagram} src={award4} alt="award 4 panel" />
                </div>
            </div>
            <div className={style.images_group}>
                <div className={style.image_item}>
                    <img className={style.diagram} src={award5} alt="award 5 panel" />
                </div>
                <p>
                    Relatório do Banco Interamericano de Desenvolvimento – BID. “<b>Las startups govtech y el futuro del
                    gobierno en Brasil</b>”, Set/2020, que listou as 80 StartUps de maior impacto no Setor Público em 2020.
                </p>
            </div>
            <div className={style.images_group}>
                <p>
                    Participação, a convite da BrazilLab e Bay Brazil, de missão ao Vale do Silício, 2019. Participação no
                    Congresso Bay Brazil, na Universidade de Stanford.
                </p>
                <div className={style.image_item}>
                    <img className={style.diagram} src={award6} alt="award 6 panel" />
                </div>
            </div>
        </div >
    )
}

export default Awards
