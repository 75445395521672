import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./style.module.css";

const Services = () => {
  return (
    <div className={style.container} id="servicos">
      <div className={style.title}>
        <h2>Nossos Serviços</h2>
      </div>

      <div className={style.description}>
        <p>
          Com vasta experiência adquirida em mais de 90.000 horas de projetos de
          Business Analytics & Data Mining com Big Data, tanto no Setor Público
          quanto no Setor Privado, a Fábrica de Negócio conta com uma equipe
          capacitada, composta por Cientistas de Dados, Arquitetos e Analistas
          de Business Analytics, que possibilitam à Fábrica de Negócio utilizar
          as melhores práticas para desenvolver produtos e serviços com
          qualidade e prazo compatíveis com as demandas de nossos clientes.
          Dentre os serviços destacamos:
        </p>
      </div>

      <div className={style.services}>
        <div/>
        <div className={style.development}>
          {/* <h2 className={style.sub_title}>
            StartUp focada no Setor Público, especializada nas principais 
            plataformas Open Source de Business Analytics
          </h2> */}

          <ul className={style.list}>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Desenvolvimento e execução de
              projetos de Business Intelligence/Analytics
            </li>
            <br />
            <li>
              <FontAwesomeIcon icon={faCheck} /> Desenvolvimento de algoritmos
              de inteligência artificial para Mineração de Dados (Data Mining)
              em grandes volumes de dados
            </li>
            <br />
            <li>
              <FontAwesomeIcon icon={faCheck} /> Descoberta de Conhecimento em
              bases de dados através de tencologias de Big Data utilizando bases
              NOSQL e bases de dados exclusivas de Big Data, como por exemplo
              Cassandra, Mongo DB, Hadoop e HP Vertica, dentre outras
            </li>
            <br />
            <li>
              <FontAwesomeIcon icon={faCheck} /> Integração e Migração de fontes
              de dados heterogêneas (txt, xls, pdf, csv, dbf, DB2, Oracle,
              Hanna, SQL Server, PostGres, ADABAS, dentre outras 70 fontes de
              dados)
            </li>
            <br />
            <li>
              <FontAwesomeIcon icon={faCheck} /> Limpeza e estruturação de Dados
              (Data Cleaning)
            </li>
            <br />
          </ul>
        </div>
        <div/>
      </div>
    </div>
  );
};

export default Services;
