import { sessionService } from "redux-react-session";
import axios from "../../axios";

export const login = (user, history) => {
  return async () => {
    try {
      let response = await axios.post("/login", user);

      await sessionService.saveSession(response.data);
      await sessionService.saveUser(response.data);

      history.push("/admin");
    } catch (e) {
      // console.log("Erro ao fazer login");
      console.log(e);
    }
  };
};

export const logout = history => {
  return async () => {
    await sessionService.deleteSession();
    await sessionService.deleteUser();
    history.push("/login");
  };
};
