import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TopNavBar from "../../components/TopNavBar";
import styles from "./style.module.css";
import Scrollspy from "react-scrollspy";
import style from "./style.module.css";

class MainLayout extends Component {
  state = {
    changeStyle: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = event => {
    let scrollTranslate = window.pageYOffset;

    if (scrollTranslate > 60) {
      this.setState({ changeStyle: true });
    } else {
      this.setState({ changeStyle: false });
    }
  };

  render() {
    return (
      <div>
        <Scrollspy
          items={[
            // "news",
            // "objetivo",
            "quem_somos",
            "produtos",
            "servicos",
            "clientes",
            "destaques_midia",
            "premiacoes",
            "parcerias",
            "contato"
          ]}
          currentClassName={style.is_current}
        >
          <TopNavBar changeStyle={this.state.changeStyle} />
        </Scrollspy>

        <div className={styles.content}>{this.props.children}</div>
      </div>
    );
  }
}

export default withRouter(MainLayout);
